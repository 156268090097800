export const GET_AUTH = 'GET_AUTH';
export const ADD_AUTH = 'ADD_AUTH';
export const REMOVE_AUTH = 'REMOVE_AUTH';

export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';

export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';

export const UPDATE_REALM = 'UPDATE_REALM';
export const UPDATE_CURRENT_REALM = 'UPDATE_CURRENT_REALM';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_REALM_ADMIN = 'UPDATE_REALM_ADMIN';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_PERMITTED_REALM = 'UPDATE_PERMITTED_REALM';

export const REFRESH_REALMS = 'REFRESH_REALMS';
export const REFRESH_CLIENTS = 'REFRESH_CLIENTS';

export const REFRESH_ASSETS = 'REFRESH_ASSETS';
