// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/basicui/styles/index.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/reach/styles/index.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/writeup/styles/index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".basicui-dark .basicui-input{background-color:var(--theme-primary-transparent-50);border-color:var(--theme-primary-600)}html body *{font-family:\"Roboto\",sans-serif}body{margin:0;background-color:var(--theme-adaptive-50);color:var(--theme-adaptive-50i)}", "",{"version":3,"sources":["webpack://./src/basicui.scss","webpack://./src/index.scss"],"names":[],"mappings":"AACI,6BACI,oDAAA,CACA,qCAAA,CCIR,YACI,+BAAA,CAGJ,KACI,QAAA,CACA,yCAAA,CACA,+BAAA","sourcesContent":[".basicui-dark {\r\n    .basicui-input {\r\n        background-color: var(--theme-primary-transparent-50);\r\n        border-color: var(--theme-primary-600);\r\n    }\r\n    // .basicui-input:hover,\r\n    // .basicui-input:focus,\r\n    // .basicui-input:active {\r\n    //     background-color: var(--theme-primary-transparent-300);\r\n    //     border-color: var(--theme-primary-700);\r\n    // }\r\n}\r\n","@import '../node_modules/basicui/styles/index.css';\r\n@import '../node_modules/reach/styles/index.css';\r\n@import '../node_modules/writeup/styles/index.css';\r\n@import './basicui.scss';\r\n@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');\r\n\r\n\r\nhtml body * {\r\n    font-family: 'Roboto', sans-serif;\r\n}\r\n\r\nbody {\r\n    margin: 0;\r\n    background-color: var(--theme-adaptive-50);\r\n    color: var(--theme-adaptive-50i);\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
